<template>
	<div id="news" :style="{'background-color': bgColor}">
		<v-head currentIndex=3></v-head>
		<div class="news-bannel" v-if="showBannel">
            <div class="search-container">
                <div class="search-box">
                    <div class="loading-category" v-if="loadingCategory">
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    <a-dropdown :trigger="['click']" @visibleChange="onDropdownChange">
                        <div class="news-cc-value" :data-value="categoryValue">{{categoryName || "所有科别"}}</div>
                        <a-menu slot="overlay" v-if="categorys.length">
                            <a-menu-item @click="onCategoryItemClick('', '')" key="-1">所有科别</a-menu-item>
                            <a-menu-divider />
                            <a-menu-item @click="onCategoryItemClick(category.codedesc, category.codeid)" :key="category.codeid" v-for="category in categorys">
                                {{category.codedesc}}
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <input type="text" class="news-search-kw" v-model="keyword" placeholder="輸入關鍵字,例如：失眠，心悸，腹痛…" @keyup="onKeyEnter"/>
                    <div class="btn-search-news" @click="doSearch">搜索資訊</div>
                </div>
                <div class="search-hot-kws" v-if="keywords.length">
                    <span>熱門搜索：</span>
                    <ul class="hot-kws">
                        <li class="hot-kw" v-for="(keyword, index) in keywords" :key="index"><router-link :to="'/news/list?keyword=' + keyword" v-text="keyword"></router-link>{{ index >= (keywords.length - 1) ? '' : '、'}}</li>
                    </ul>
                </div>
            </div>
        </div>
		<div class="news-content">
			<router-view class="router-view" :kk="params" :callback="onCallback"></router-view>
			<div class="r-box">
                <div class="hot-news" v-if="hotNews.length">
                    <h3 class="hot-news-title">熱門文章</h3>
                    <ul class="hot-news-list">
                        <li class="hot-news-item" v-for="(news, index) in hotNews" :key="index"><router-link :to="`/news/detail/${news.newsid}`" v-text="news.title"></router-link></li>
                    </ul>
                </div>
                <div class="idfa">
                    <div class="app-info">
                        <span class="app-name">【大醫APP】</span>
                        <span class="app-desc">隨時隨地咨詢醫師，查看健康資訊</span>
                        <div class="app-qrcode-container">
                            <div class="app-qrcode">
                                <img src="../../../assets/imgs/qr_code.png" alt="">
                                <span class="qrcode-title">掃碼下載大醫App</span>
                            </div>
                        </div>
                    </div>
                    <div class="web-intro">
                        <div class="fr-link-list">
                          <a class="fr-link-item" v-for="item in this.constants.friendlyLinks" :href="item.url" target="_blank" v-text="item.title"></a>
                        </div>
                        <div class="safe-icos">
                            <a href="javascript:;" data-url="https://seal.godaddy.com/verifySeal?sealID=H1ahlwwmjgaLEmPXgaSipSp5JvRqE5ESwDOgW4qjVZ4kYfENswwF51bdQiuz" class="safe-icon-link"><i class="icon-safe icon1"></i></a>
                            <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon2"></i></a>
                            <a href="https://www.mcafeesecure.com/verify?host=ectcm.com&popup=1" target="_blank" class="safe-icon-link"><i class="icon-safe icon3"></i></a>
                            <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon4"></i></a>
                        </div>
                        <div class="about-us-list">
                            <a class="about-us-item" href="/home/about">關於我們</a>
                            <a class="about-us-item feedback" href="/home/feedback">用戶反饋</a>
                            <a class="about-us-item" href="/home/disclaimer">使用條款及免責聲明</a>
                            <a class="about-us-item" href="/home/privacy">私隱政策</a>
                            <a class="about-us-item" href="/home/contact">聯絡我們</a>
                        </div>
                        <router-link class="link-protocol" to="/home/disclaimer">使用條款及免責聲明</router-link>
                        <div class="copyright">Copyright ©{{new Date().getFullYear()}} 版權所有 <br/>深圳本草通网络有限公司<br/>Shenzhen Bencaotong Network Co., Ltd. </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
	import vHead from '@/layouts/Head.vue'
	export default {
		components: {
			vHead,
        },
		data(){
            return {
                showBannel: false,
                loadingCategory: false,
                bgColor: '#00000000',
                categorys: [],
                categoryName: '',
                categoryValue: '',
                keyword: '',
                params: null,
                keywords: [],
                hotNews: [],
            }
        },
        methods: {
            onKeyEnter: function(v) {
                if(v.key === 'Enter') {
                    this.doSearch()
                }
            },
            onCallback: function(keywords) {
                if(keywords && Object.prototype.toString.call(keywords) == '[object String]') {
                    this.keywords = keywords.split(',')
                }
            },
            doSearch: function(){
                this.params = null
                setTimeout(() => {
                    this.params = {keyword: this.keyword, categoryid: this.categoryValue}
                }, 10)
            },
            onDropdownChange: function(v){
                if(this.loadingCategory) {
                    return
                }
                if(v && !this.categorys.length) {
                    this.loadingCategory = true
                    this.$store.dispatch('getNewsCategorys', {})
                    .then((rs) => {
                        this.categorys = rs || []
                        this.loadingCategory = false
                    }).catch((rs) => {
                        this.loadingCategory = false
                    })
                }
            },
            onCategoryItemClick: function(name, value) {
                this.categoryName = name
                this.categoryValue = value
            },
        },
        mounted: function() {
            if(!this.hotNews.length) {
                this.$store.dispatch('getHotNewsList', {}).then(d=>{
                    this.hotNews = d
                }).catch(e=>{
                    this._toast.warning(this, e)
                })
            }
            var path = this.$route.path
            if(path.startsWith('/news/list')) {
                this.showBannel = true
            } else {
                this.showBannel = false
            }
            if(path.startsWith('/news/detail/')) {
                this.bgColor = '#F7F7F7'
            }
        },
        watch: {
            $route(to,from){
				if(to.path.startsWith('/news/list')) {
                    this.keyword = this.$route.query.keyword
                    this.showBannel = true
                } else {
                    this.showBannel = false
                }
                if(to.path.startsWith('/news/detail/')) {
                    this.bgColor = '#F7F7F7'
                }
			}
        }
	};
</script>

<style lang="scss" scoped>
   #news {
	   width: 100%;
   }
   .news-bannel > .search-container {
        width: 1000px;
        margin: 20px auto;
        height: 138px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/news/img_bannerbg@2x.png);
        position: relative;
    }

    .news-bannel .search-container > .search-box {
        height: 36px;
        line-height: 36px;
        width: 549px;
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        border-radius: 8px;
        position: absolute;
        left: 226px;
        top: 36px;
    }

    .news-bannel .search-container > .search-box > .loading-category {
        position: absolute;
        left: 0;
        top: 0;
        width: 82px;
        padding-top: 2px;
        text-align: center;
    }

    .news-bannel .search-container > .search-hot-kws {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 97px;
        font-weight: 400;
        color: #fff;
        font-size: 14px;
    }

    .news-bannel .search-container > .search-hot-kws > .hot-kws {
        display: flex;
        flex-direction: row;
    }

    .news-bannel .search-container > .search-hot-kws > .hot-kws > .hot-kw {
        margin-right: 5px;
    }

    .news-bannel .search-container > .search-box > .news-cc-value  {
        padding-left: 17px;
        padding-right: 31px;
        position: relative;
        border: 1px solid #2B99A6;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
    }

    .news-bannel .search-container > .search-box > .news-cc-value::after {
        content: '';
        position: absolute;
        right: 11px;
        top: 50%;
        width: 10px;
        height: 7px;
        transform: translateY(-50%);
        background-image: url(../../../assets/imgs/pc/img_xial2@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .news-bannel .search-container > .search-box > .news-search-kw  {
        flex: 1;
        padding-left: 9px;
        line-height: 36px;
        border-top: 1px solid #2B99A6;
        border-bottom: 1px solid #2B99A6;
        box-sizing: border-box;
        font-weight: bold;
        color: #231F20;
        font-size: 16px;
    }

    .news-bannel .search-container > .search-box > .btn-search-news {
        width: 78px;
        line-height: 36px;
        background-color: #36C4D0;
        color: #fff;
        font-size: 16px;
        height: 36px;
        text-align: center;
        font-weight: bold;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #36C4D0;
        box-sizing: border-box;
        cursor: pointer;
    }

	.news-content {
		display: flex;
		flex-direction: row;
		width: 1000px;
		margin: 0 auto;
        margin-top: 10px;
	}

	.news-content > .router-view {
		flex: 1;
	}

    .news-content > .r-box {
        width: 298px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .idfa {
        display: flex;
        flex-direction: column;
        position: unset;
        top: 0px;
        left: 0px;
    }

    .idfa.fixed {
        position: fixed;
    }

    .r-box .web-intro {
        width: 298px;
        background-color: #fff;
    }

    .r-box .web-intro .fr-link-list {
        display: grid;
        grid-template-columns: 136px 147px;
        grid-template-rows: 34px 34px 34px 34px 34px;
        padding-top: 14px;
        grid-auto-flow: column;
    }

    .r-box .web-intro .fr-link-list .fr-link-item {
        line-height: 34px;
        padding-left: 15px;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
    }

    .r-box  .safe-icos {
        padding-left: 18px;
        padding-top: 10px;
    }

    .r-box  .safe-icos .safe-icon-link {
        display: inline-block;
        margin-top: 13px;
        height: 32px;
    }

    .r-box  .safe-icos .safe-icon-link:nth-child(1) .icon-safe {
        display: inline-block;
        width: 131px;
        height: 32px;
        margin-right: 12px;
        background-image: url(../../../assets/imgs/pc/img_ver@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .r-box  .safe-icos .safe-icon-link:nth-child(2) .icon-safe {
        display: inline-block;
        width: 82px;
        height: 32px;
        background-image: url(../../../assets/imgs/pc/img_peiyu@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .r-box  .safe-icos .safe-icon-link:nth-child(3) .icon-safe {
        display: inline-block;
        width: 94px;
        height: 32px;
        margin-right: 8px;
        background-image: url(../../../assets/imgs/pc/img_mc@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .r-box .safe-icos .safe-icon-link:nth-child(4) .icon-safe {
        display: inline-block;
        width: 86px;
        height: 32px;
        background-image: url(../../../assets/imgs/pc/img_sll@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .r-box .about-us-list {
        margin-left: 9px;
        margin-top: 30px;
    }

    .r-box .about-us-list > .about-us-item {
        padding-left: 6px;
        font-weight: 400;
        line-height: 13px;
        height: 13px;
        font-size: 14px;
        color: #636363;
    }

    .r-box .about-us-list > .about-us-item:nth-child(n+2) {
        position: relative;
    }

    .r-box .about-us-list > .about-us-item:nth-child(n+2)::before {
        content: '';
        position: absolute;
        left: -1px;
        top: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #636363;
    }

    .r-box .link-protocol {
        display: block;
        margin-left: 16px;
        margin-top: 30px;
        font-weight: 400;
        line-height: 13px;
        height: 13px;
        font-size: 14px;
        color: #636363;
    }

    .r-box .copyright {
        padding: 7px 0px 39px 16px;
        font-size: 14px;
        color: #636363;
        line-height: 26px;
    }

    /* app信息 start */
    .r-box .app-info {
        background-color: #FFF4EF;
        width: 298px;
        height: 210px;
        display: flex;
        flex-direction: column;
    }

    .r-box .app-info > .app-name {
        color: #FF7800;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        margin-left: 16px;
        margin-top: 20px;
    }

    .r-box .app-info > .app-desc {
        color: #FF7800;
        font-size: 14px;
        line-height: 14px;
        margin-left: 15px;
        margin-top: 10px;
    }

    .r-box .app-info > .app-qrcode-container {
        display: flex;
        flex-direction: row;
    }

    .r-box .app-info > .app-qrcode-container .app-qrcode {
        margin-left: 15px;
        margin-right: 29px;
        margin-top: 23px;
        width: 90px;
    }

    .r-box .app-info > .app-qrcode-container img {
        width: 90px;
        height: 90px;
        vertical-align: top;
    }

    .r-box .app-info > .app-qrcode-container .qrcode-title {
        font-weight: 400;
        color: #231F20;
        font-size: 14px;
        height: 13px;
        white-space: nowrap;
        line-height: 30px;
    }

    /* app信息 end */

    /* 热门文章 start */
    .hot-news > .hot-news-title {
        height: 38px;
        line-height: 38px;
        color: black;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        padding-left: 40px;
        background-color: #fff;
        border-bottom: 1px solid #E8E8E8;
    }

    .hot-news > .hot-news-title::after {
        position: absolute;
        content: '';
        left: 16px;
        top: 11px;
        width: 15px;
        height: 16px;
        background-image: url(../../../assets/imgs/pc/news/img_remen@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .hot-news > .hot-news-list {
        width: 298px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding-bottom: 33px;
        padding-top: 3px;
        margin-bottom: 10px;
    }

    .hot-news > .hot-news-list > .hot-news-item {
        width: 298px;
        height: 21px;
        margin-top: 16px;
    }

    .hot-news > .hot-news-list > .hot-news-item > a {
        width: 254px;
        height: 21px;
        line-height: 21px;
        font-size: 16px;
        color: #231F20;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        position: relative;
        padding-left: 44px;
    }

    .hot-news > .hot-news-list > .hot-news-item > a::after {
        content: '';
        position: absolute;
        left: 17px;
        top: 0px;
        color: #2B99A6;
        font-size: 12px;
        font-weight: 400;
        width: 17px;
        height: 21px;
        border-radius: 8px;
        background-color: #DCF7FB;
        text-align: center;
        line-height: 21px;
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(n+4) {
        height: 16px;
        line-height: 16px;
        margin-top: 18px;
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(n+4) > a::after {
        width: 16px;
        height: 16px;
        line-height: 16px;
        top: 3px;
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(1) > a::after {
        width: 17px;
        height: 21px;
        background-image: url(../../../assets/imgs/pc/news/img_one@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(2) > a::after {
        width: 17px;
        height: 21px;
        background-image: url(../../../assets/imgs/pc/news/img_two@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(3) > a::after {
        width: 17px;
        height: 21px;
        background-image: url(../../../assets/imgs/pc/news/img_three@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(4) > a::after {
        content: '4';
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(5) > a::after {
        content: '5';
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(6) > a::after {
        content: '6';
    }

    .hot-news > .hot-news-list > .hot-news-item:nth-child(7) > a::after {
        content: '7';
    }
    /* 热门文章 end */

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
